<template>
    <div class="GravesList grave_cards">
        <div class="item" v-for="(prs, ind) in grvList"
             :key="`${prs.prs_id}-${ind}`"
             v-show="!prs.hide">
            <el-image :src="https + prs.prs_grv_img_thumb"
                      :preview-src-list="[https + prs.prs_grv_img]">
                @error="prs.hide = true">
            </el-image>
            <div class="text">
                <div class="title">
                    <A :href="`javascript:void(${prs.prs_id})`"
                       @click="handleShowPerson(prs)">
                        <template v-if="locale === 'ru' || !prs.prs_F_en">
                            <text-highlight :queries="[filter.predicate, (filter.sName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_F}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate,(filter.fName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_I}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate, (filter.mName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_O}}
                            </text-highlight>
                            <template v-if="prs.prs_title">, {{prs.prs_title}}</template>
                        </template>

                        <template v-if="locale === 'en' || !prs.prs_F">
                            <text-highlight :queries="[filter.predicate, (filter.sName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_F_en}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate,(filter.fName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_I_en}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate, (filter.mName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_O_en}}
                            </text-highlight>

                            <template v-if="prs.prs_title_en">, {{prs.prs_title_en}}</template>
                        </template>
                    </A>
                </div>
                <div class="years" v-if="prs.bYear || prs.grYear ">
                    <template v-if="prs.bYear">{{prs.bYear}}</template>
                    <template v-else>?</template>
                    -
                    <template v-if="prs.grYear">{{prs.grYear}}</template>
                    <template v-else>?</template>
                </div>
                <div class="grave">
                    <div>
                        <div>{{prs.prs_graveyard}}</div>
                        <span class="commaBefore">{{prs.prs_gr_town}}</span>
                        <span class="commaBefore">{{prs.prs_gr_country}}</span>
                    </div>
                    <div class="stats">
                        <div v-if="isAdmin">
                            <span>{{prs.prs_visits}}</span>
                            <i class="fas fa-eye"></i>
                        </div>
                        <el-tooltip effect="light" :content="$t('label.putFlower')">
                            <div class="flower" @click="handlePutFlower(prs)">
                                <span>{{prs.prs_flowers}}</span>
                                <img src="/images/rose32.png">
                            </div>
                        </el-tooltip>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "GravesList",
        props: {
            grvList: {
                type: Array,
                default: () => ([])
            },
            filter: {
                type: Object,
                default: () => ({
                    predicate: '',
                    fName: '',
                    sName: '',
                    mName: '',
                    profStat: '',
                    bDateFrom: '',
                    bDateTo: '',
                    bPlace: '',
                    dDateFrom: '',
                    dDateTo: '',
                    dPlace: '',
                    graveyard: '',
                    category: '',
                })
            },
        },
        data() {
            return {
                show: {
                    card: false
                },
                https: this.$isDev ? 'https://rusabroad.ru/' : '',
            }
        },
        computed: {
            locale() {
                return this.$store.state.locale;
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            mrX() {
                return this.$isDev ? 'https://rusabroad.ru/images/avatar1.svg' : '/images/avatar1.svg';
            }
        },
        filters: {
            date(val) {
                return (val.length > 7 && !val.match(/[А-Яа-яA-Za-z ]/)) ? moment(val, "YYYY-M-D").format('DD MMM YYYY') : val;
            }
        },
        methods: {
            handleShowPerson(prs) {
                this.$emit('handleShowPerson', prs);
            },
            handleEditPerson(prs) {
                this.$emit('editPerson', prs);
            },
            handleImgError(e) {
                // console.log('eee', regDAte);
                e.target.src = this.$isDev ? 'https://rusabroad.ru/images/avatar1.svg' : '/images/avatar1.svg';
            },
            handlePutFlower(prs) {
                let self = this,
                    query = {
                        data: {
                            mod: 'putFlower',
                            prs_id: prs.prs_id
                        }
                    };
                self.$req(query).then((resp, textStatus, request) => {
                    // console.log('flower:', resp);
                    prs.prs_flowers = resp.num
                })

            },
            handleDelete(prs) {
                let self = this;
                this.$confirm('Вы точно хотите удилить персону? ', 'Внимание!', {
                    confirmButtonText: 'Удалить',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'Отмена',
                    type: 'warning'
                }).then(() => {
                    this.$req({
                        data: {
                            mod: 'delUni',
                            del_id: prs.prs_id,
                            del_key: 'prs'
                        }
                    }).then(resp => {
                        if (resp.success) {
                            this.$message({
                                type: 'success',
                                message: resp.success
                            });
                            self.$emit('personDeleted', prs.prs_id);
                        } else if (resp.error) {
                            this.$message({
                                type: 'error',
                                message: resp.error
                            });
                        }
                    })
                }).catch(() => {

                });
            },


        }

    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .GravesList {
        &.grave_cards {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 15px;

            .item {
                flex-basis: 31.5%;
                padding: 15px;
                border: $border;
                border-radius: 5px;
                background: $bg-grave;
                display: flex;
                flex-direction: column;
                /*justify-content: space-between;*/
                font-size: .8rem;
                z-index: 250;

                .el-image {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    height: 190px;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }

                .title {
                    line-height: 1.2;
                    margin: 10px 0 3px;
                    font-weight: 600;
                    font-size: .9rem;
                }
            }
        }

        .text {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .grave {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: auto;
        }

        .stats {
            opacity: .65;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            white-space: nowrap;

            > div i {
                margin: 0 3px 0 7px;
            }

            span {
                font-weight: 800;
                font-size: 12px;
            }

            img {
                height: 22px !important;
                transform: rotate(45deg);
                margin-left: 2px;
            }
        }

        .flower {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

    }
</style>