<template>
    <div>
        <div class="container">
            <Head></Head>
        </div>
        <div class="container content">
            <MainMenu ref="MainMenu"></MainMenu>
            <div class="Graves Inner">
                <div class="leftMenu">
                    <el-menu :default-active="activeIndex"
                             mode="vertical"
                             @select="handleSelect">
                        <el-menu-item index="">{{ $t('label.allCountries') }}</el-menu-item>
                        <el-menu-item :index="item" v-for="(item,i) in countries" :key="i"
                                      v-html="item"></el-menu-item>
                    </el-menu>
                </div>
                <div class="wrap" ref="wrap">
                    <div class="pageTitle">
                        <h1>
                            <div>
                                {{ pageTitle }}
                                <span v-if="!load.total"
                                      class="label">{{total.list}}</span>
                                <span v-else class="label"><i class="fas fa-spinner fa-pulse"></i></span>
                            </div>
                            <div class="predicateSearch">
                                <el-input v-model="filter.predicate"
                                          clearable
                                          @change="handleSearch">
                                    <el-button slot="append"
                                               @change="handleSearch">
                                        <i class="fal fa-search"></i>
                                    </el-button>
                                </el-input>
                            </div>
                        </h1>
                    </div>
                    <div class="grvList" ref="listContainer"
                         v-loading="load.grvList">
                        <GraveList :grvList="grvList"
                                   :filter="filter"
                                   @handleShowPerson="handleShowPerson"/>

                        <el-pagination
                                small
                                background
                                :hide-on-single-page="true"
                                layout="prev, pager, next"
                                :total="total.list"
                                :page-size=20
                                :current-page.sync="page"
                                @current-change="onChangePage"/>
                    </div>
                </div>
            </div>
        </div>
        <el-backtop target=".wrap" :bottom="20" :right="20"></el-backtop>
    </div>
</template>

<script>
    import GraveList from "../components/GravesList";
    import MainMenu from "../components/MainMenu";
    import Head from "../components/Head";

    export default {
        name: 'Graves',
        components: {
            Head,
            GraveList, MainMenu
        },
        props: {},
        data() {
            return {
                activeIndex: "",
                curIndex: '',
                pageTitle: '',
                grvList: [],
                personToShow: {},
                page: 1,
                filter: {
                    predicate: ''
                },
                show: {
                    personCard: false
                },
                total: {
                    categories: 0,
                    list: 0
                },
                load: {
                    grvList: true,
                    total: true
                },
            }
        },
        mounted() {
            this.$refs.MainMenu.updateCurPage('graves');
            this.refreshGraveList();
            this.changePageTitle();

        },
        computed: {
            locale() {
                return this.$store.state.locale;
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            countries() {
                return this.$store.getters.initData.grCountries[this.locale];
            },

        },
        watch: {
            locale(newVal) {
                let self = this;
                self.$nextTick(() => {
                    self.handleSelect(self.curIndex);
                    self.changePageTitle(newVal);
                });
            }
        },
        methods: {
            refreshGraveList() {
                let self = this,
                    grCountry = self.curIndex,
                    query = {
                        data: {
                            mod: 'show_person_list',
                            count: 21,
                            grCountry: grCountry,
                            locale: self.locale,
                            searchType: 'grave',
                            text: self.filter.predicate,
                            page: self.page
                        }
                    };
                self.load.grvList = self.load.total = true;
                self.$req(query).then((resp, textStatus, request) => {
                    self.grvList = resp.prs;
                    self.load.grvList = self.load.total = false;
                    self.total.list = resp.total;
                    self.$refs.listContainer.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    // console.log('request', request.getResponseMainMenuer('content-type'),' | textStatus:', textStatus);
                })
            },
            handleSearch() {
                this.page = 1;
                this.refreshGraveList(this.curIndex)
            },
            handleShowPerson(prs) {
                this.personToShow = prs;
                this.$nextTick(() => {
                    this.$store.commit("showPersonCard", prs);
                });
            },
            handleAddPerson() {
                let typeId = this.curIndex !== 'alphaList' ? this.curIndex : null;
                this.handleEditPerson({
                    prs_type_id: typeId
                })
            },
            handleEditPerson(prs) {
                this.personToEdit = prs;
                this.$nextTick(() => {
                    this.$store.commit("editPerson", prs);
                });
            },
            handleUpdatePerson(prs_id) {
                let self = this;
                if (prs_id && self.personToShow.prs_id === prs_id) {
                    this.$store.commit("personCard", {refresh: true});
                }
                self.refreshGraveList(self.curIndex);
            },
            handleDeletedPerson(prs_id) {
                let self = this;
                self.refreshGraveList(self.curIndex);
            },
            handleSelect(index) {
                let self = this;
                self.page = 1;
                self.curIndex = index;
                self.changePageTitle(self.locale);
                self.refreshGraveList(index);
                self.scrollTop();
            },
            changePageTitle(locale) {
                let self = this;
                self.pageTitle = self.$t('label.graves') + ': ' + (self.curIndex ? self.curIndex : self.$t('label.allCountries'));
                self.scrollTop()
            },
            onChangePage(page) {
                this.page = page;
                this.refreshGraveList(this.curIndex);
            },
            scrollTop() {
                this.$refs.wrap.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });

            }
        },
    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .Graves {

    }

</style>